import React from 'react';
// import Cursor from '../components/Cursor'

const Passion = () => {
    return (
        <div>
           passion projects
        </div>
    );
};

export default Passion;